<template>
	<section>

		<div class="banner">
			<div class="banner-con">
				<div class="event">
					<div class="banner-title">{{pagedata.title}}</div>
					<div class="banner-pro">{{pagedata.intro}}</div>
				</div>
			</div>
		</div>
		<div class="section" style="margin-top: 40px;">
			<div class="section-content">
				<div class="list-item" v-for="(val,index) in detailD" :key="'del'+index">
					<div class="text" v-if="val.modeid==1">{{val.text}}</div>
					<div class="image" v-else-f="val.modeid==2">
						<img :src="val.image_url">
					</div>
					<div class="video" v-else-f="val.modeid==3"></div>


				</div>

			</div>
		</div>
		
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	actionid:'',
        	pagedata:{},
        	detailD:[]
        }
    },
	mounted(){
		console.log('this is partners');
		this.actionid = this.$route.query.id;
		this.pageinit();
	},
	methods: {
		pageinit(){
			console.log(this.actionid)
			let _this = this;
			this.axios.post('/action/nologin/detail', {
                actionid: this.actionid
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {

                    _this.$nextTick(function () {
                        _this.pagedata = res.extra;
                        _this.detailD = res.extra.detail
                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		}
	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		min-width: 1024px;
		height: 363px;
		background-color: #F2F2F2;
		overflow-y: hidden;
	}
	.banner-con{
		width: auto;
		height: 100%;
	    margin-left: auto;
	    margin-right: auto;
	    /*margin-top: 160px;*/
	    max-width: 1374px;
	    padding: 0 22px;
    }
    .event{
		width: 409px;
		height: 100%;
	    display: flex;
    	flex-direction: column;
    	justify-content: flex-end;
	    padding-bottom: 40px;
    }
    .banner-title{
    	font-size: 24px;
    	font-weight: bold;
    	color: #333;
    }
    .banner-pro{
    	font-size: 14px;
    	color: #848282;
    	padding-top: 14px;
    }
    .text{
    	font-size: 14px;
    	color: #333;
    }
    .image img{
    	width: 100%;
    	height: auto;
    }
</style>